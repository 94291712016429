<template>
    <v-container fluid class="pa-0 ma-0">
        <v-row row wrap>
            <template v-if="loading">
                <v-col cols="12" class="text-center" mt-3>
                <v-progress-circular
                    :width="3"
                    color="blue"
                    indeterminate
                ></v-progress-circular>
                </v-col>
            </template>
            <template v-else>
                <v-col xs-12>
                    <v-tabs
                        color="light"
                        icons-and-text
                        grow
                    >
                        <v-tabs-slider color="blue"></v-tabs-slider>

                        <v-tab href="#tab-1">
                            Ordered
                            <v-icon>schedule</v-icon>
                        </v-tab>

                        <v-tab href="#tab-2">
                            Completed
                            <v-icon>check</v-icon>
                        </v-tab>

                        <v-tab href="#tab-3">
                            Cancelled
                            <v-icon>close</v-icon>
                        </v-tab>

                        <v-tab-item :value="'tab-1'">
                            <order-list
                                :status="orderedTitle"
                                :orders="ordered"
                            />
                        </v-tab-item>
                        <v-tab-item :value="'tab-2'">
                            <order-list
                                :status="completedTitle"
                                :orders="completed"
                            />
                        </v-tab-item>
                        <v-tab-item :value="'tab-3'">
                            <order-list
                                :status="refundedTitle"
                                :orders="refunded"
                            />
                        </v-tab-item>
                    </v-tabs>
                    <div class="text-center">
                        <v-btn icon color="primary" @click="refresh" :loading="loading" :disabled="loading">
                            <v-icon>refresh</v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </template>
        </v-row>
    </v-container>
</template>

<script>
import SquareReceipt from '@/components/payment/SquareReceipt'
import { mapGetters } from 'vuex'
import OrderList from './OrderList'
import api from '@/store/api'
import AllMixins from '@/mixins/AllMixins'
export default {
    components: {
        SquareReceipt,
        OrderList
    },
    data () {
        return {
            orderedTitle: "Ordered",
            readyTitle: "Ready for Pickup",
            completedTitle: "Completed",
            refundedTitle: "Canceled",
        }
    },
    mixins: [AllMixins],
    mounted () {
        api.loadUserOrders()
        this.getAvailableProducts() //needed to switch fulfillment
    },
    computed: {
        ...mapGetters({
            orders: 'getUserOrders',
            loading: 'loading'
        }),
        ordered () {
            return this.orders.filter(function(order){
                return order.status == "Ordered"
            })
        },
        ready () {
            return this.orders.filter(function(order){
                return order.status == "Ready for Pickup"
            })
        },
        completed () {
            return this.orders.filter(function(order){
                return order.status == "Completed"
            })
        },
        refunded () {
            return this.orders.filter(function(order){
                return order.status == "Refunded"
            })
        }
    },
    methods: {
        refresh () {
            api.loadUserOrders()
        },
    }
}
</script>